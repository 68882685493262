<template>
    <div>
        <img class="example-headImg" src="../assets/image/selected/08-1banner.png"/>
        <div class="head-explain">
            <img class="head-img-logo" src="../assets/image/selected/08-1iSales.png"/>
            <div class="head-explain-title">销售效能管理</div>
            <div class="head-explain-content">IQVIA iSales 面向医药和器械行业销售效能管理</div>
            <div class="head-explain-content" style="margin-top:-3rem">成为客户首选的科技创新伙伴。</div>
        </div>
        <div class="container-1">
            <div class="saled-box1">
                <div class="title1">
                    IQVIA iSales 面向医药和器械行业销售效能管理一站式数字化平台。<br/>
                    IQVIA iSales完整支持区域指标规划，销量达成统计，销售激励管理，全面赋能SFE和销售团队制定销售计划、跟踪销售执行与达成。
                </div>
            </div>
        </div>
        <div class="container-2" style="height:90rem">
            <div class="saled-box2">
                <div class="container">
                    <div class="card">
                        <img src="../assets/image/selected/08-1全面覆盖.png"/>
                        <div class="title">全面覆盖<br/>
                        销售效能管理职能</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-1灵活应对.png"/>
                        <div class="title">灵活应对<br/> 院内零售多团队业务</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-1标准化.png"/>
                        <div class="title">标准化集中化销售数据，<br/>
                        进而实现业务洞察</div>
                    </div>
                </div>
                <img class="saled-box2-img" src="../assets/image/selected/08-1中图.png"/>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'销售效能管理,销售效能管理职能,院内零售多团队业务,标准化集中化销售数据' },
        { name:'description', content:'IQVIA iSales 面向医药和器械行业销售效能管理,成为客户首选的科技创新伙伴。' }
        ]
    },
    data() {
        return {
        };
    },

    mounted() {
        this.activeIndex = '2'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.saled-box1{
    width: 139.7rem;
    height: 8.8rem;
    margin: auto;
    margin-top: 4.4rem;
    margin-bottom: 2rem;
    .title1{
        width: 139.7rem;
        height: 8.8rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #333333;
        line-height: 3rem;
        margin-top: 2rem;
        
    }
}
.saled-box2{
    width: 86rem;
    margin: auto;
    .container{
        width: 86rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            width: 26rem;
            display: flex;
            margin-top: 5rem;
            margin-bottom: 5.6rem;
            img{
                width: 7rem;
                height: 7rem;
                float: left;
            }
            .title{
                margin-left: 2.1rem;
                width: 18rem;
                font-size: 1.4rem;
                font-family: 'CN_Normal';
                text-align: justify;
                margin-top: 1.5rem;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }
    .saled-box2-img{
        width: 84.1rem;
        height: 61.6rem;
    }
}
</style>
